.logo {
  /* background-color: #fff; */
  border-right: 1px solid #3c4b64;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.img {
  height: 64px;
  object-fit: cover;
  padding: 5px;
}
