.dark-mode .p-datatable-tbody .role-admin {
  color: $dark-yellow-700;
}

.dark-mode .p-datatable-tbody .role-all {
  color: $dark-blue-700;
}

.dark-mode .p-datatable-tbody .role-user {
  color: $dark-cyan-700;
}
