.dark-mode .dropdown-menu {
  // scss-docs-start header-css-vars
  --#{$prefix}dropdown-bg: #{$dark-header-bg};
  --#{$prefix}dropdown-link-hover-bg: #{$dark-dropdown-link-hover-bg};
  // --#{$prefix}header-color: #{$dark-header-color};
  // --#{$prefix}header-border-color: #{$dark-header-border-color};
  //--#{$prefix}header-border: #{$dark-header-border-width} solid var(--#{$prefix}header-border-color);
  // --#{$prefix}header-hover-color: #{$dark-header-hover-color};
  // --#{$prefix}header-disabled-color: #{$dark-header-disabled-color};
  // --#{$prefix}header-active-color: #{$dark-header-active-color};
  // --#{$prefix}header-brand-padding-y: #{$dark-header-brand-padding-y};
  // --#{$prefix}header-brand-color: #{$dark-header-brand-color};
  // --#{$prefix}header-brand-hover-color: #{$dark-header-brand-hover-color};
  // --#{$prefix}header-toggler-bg: #{$dark-header-toggler-bg};
  // --#{$prefix}header-toggler-color: #{$header-toggler-color};
  // --#{$prefix}header-toggler-hover-color: #{$header-toggler-hover-color};
  // --#{$prefix}header-toggler-icon-bg: #{escape-svg($dark-header-toggler-icon-bg)};
  // --#{$prefix}header-toggler-hover-icon-bg: #{escape-svg(
  //     $dark-header-toggler-hover-icon-bg
  //   )};
  // --#{$prefix}header-divider-border-color: #{$dark-header-divider-border-color};
  // --#{$prefix}header-divider-border: #{$dark-header-divider-border-width} solid var(--#{$prefix}header-divider-border-color);
  //background: var(--#{$prefix}header-bg);
  //border-bottom: var(--#{$prefix}header-border);
  //background-color: $dark-gray-500;
}
