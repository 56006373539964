//title
.dark-mode legend.p-fieldset-legend {
  background: #434445;
  border: 1px solid rgb(56, 61, 63);
  color: $dark-main-txt;
}

//body
.dark-mode fieldset.p-fieldset {
  background: $dark-main-bg;
  border: 1px solid rgb(56, 61, 63);
  color: rgb(181, 175, 166);
}
