// Here you can add other styles
.body {
  // display: flex;
  // align-self: center;
  // width: 100%;
  // justify-content: center;
  height: 100%;
  overflow: hidden;
  background-image: url("../assets/fond.jpg");
  background-size: cover;
  padding-top: 1.2rem;
}

.sidebar-nav {
  //justify-content: center;
  margin-top: 50px;
}

.min-vh-max {
  min-height: calc(100vh - 40px);
}

.p-button-success:not(.p-button-outlined) {
  background: #81b026 !important;
  border: 1px solid #81b026 !important;
}

.position-blur {
  z-index: 3000 !important;
}

.p-button {
  padding: 0.5rem 1.25rem !important;
}
