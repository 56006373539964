.dark-mode .p-card {
  background: $dark-main-bg;
  color: $dark-main-txt;
}

// //
// // Base styles
// //

// .card {
//   // scss-docs-start card-css-vars
//   --#{$prefix}card-spacer-y: #{$dark-card-spacer-y};
//   --#{$prefix}card-spacer-x: #{$dark-card-spacer-x};
//   --#{$prefix}card-title-spacer-y: #{$dark-card-title-spacer-y};
//   --#{$prefix}card-border-width: #{$dark-card-border-width};
//   --#{$prefix}card-border-color: #{$dark-card-border-color};
//   --#{$prefix}card-border-radius: #{$dark-card-border-radius};
//   --#{$prefix}card-box-shadow: #{$dark-card-box-shadow};
//   --#{$prefix}card-inner-border-radius: #{$dark-card-inner-border-radius};
//   --#{$prefix}card-cap-padding-y: #{$dark-card-cap-padding-y};
//   --#{$prefix}card-cap-padding-x: #{$dark-card-cap-padding-x};
//   --#{$prefix}card-cap-bg: #{$dark-card-cap-bg};
//   --#{$prefix}card-cap-color: #{$dark-card-cap-color};
//   --#{$prefix}card-height: #{$dark-card-height};
//   --#{$prefix}card-color: #{$dark-card-color};
//   --#{$prefix}card-bg: #{$dark-card-bg};
//   --#{$prefix}card-img-overlay-padding: #{$dark-card-img-overlay-padding};
//   --#{$prefix}card-group-margin: #{$dark-card-group-margin};
//   // scss-docs-end card-css-vars

//   background-color: var(--#{$prefix}card-bg);
//   border: var(--#{$prefix}card-border-width) solid
//     var(--#{$prefix}card-border-color);
// }

// .card-body {
//   // Enable `flex-grow: 1` for decks and groups so that card blocks take up
//   // as much space as possible, ensuring footers are aligned to the bottom.
//   flex: 1 1 auto;
//   padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
//   color: var(--#{$prefix}card-color);
// }

// // Optional textual caps
// //

// .card-header {
//   padding: var(--#{$prefix}card-cap-padding-y)
//     var(--#{$prefix}card-cap-padding-x);
//   margin-bottom: 0; // Removes the default margin-bottom of <hN>
//   color: var(--#{$prefix}card-cap-color);
//   background-color: var(--#{$prefix}card-cap-bg);
//   border-bottom: var(--#{$prefix}card-border-width) solid
//     var(--#{$prefix}card-border-color);
// }

// .card-footer {
//   padding: var(--#{$prefix}card-cap-padding-y)
//     var(--#{$prefix}card-cap-padding-x);
//   color: var(--#{$prefix}card-cap-color);
//   background-color: var(--#{$prefix}card-cap-bg);
//   border-top: var(--#{$prefix}card-border-width) solid
//     var(--#{$prefix}card-border-color);

//   &:last-child {
//     @include border-radius(
//       0 0 var(--#{$prefix}card-inner-border-radius)
//         var(--#{$prefix}card-inner-border-radius)
//     );
//   }
// }

// //
// // Header navs
// //

// .card-header-tabs {
//   margin-right: calc(
//     -0.5 * var(--#{$prefix}card-cap-padding-x)
//   ); // stylelint-disable-line function-disallowed-list
//   margin-bottom: calc(
//     -1 * var(--#{$prefix}card-cap-padding-y)
//   ); // stylelint-disable-line function-disallowed-list
//   margin-left: calc(
//     -0.5 * var(--#{$prefix}card-cap-padding-x)
//   ); // stylelint-disable-line function-disallowed-list
//   border-bottom: 0;

//   .nav-link.active {
//     background-color: var(--#{$prefix}card-bg);
//     border-bottom-color: var(--#{$prefix}card-bg);
//   }
// }

// .card-header-pills {
//   margin-right: calc(
//     -0.5 * var(--#{$prefix}card-cap-padding-x)
//   ); // stylelint-disable-line function-disallowed-list
//   margin-left: calc(
//     -0.5 * var(--#{$prefix}card-cap-padding-x)
//   ); // stylelint-disable-line function-disallowed-list
// }

// // Card image
// .card-img-overlay {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   padding: var(--#{$prefix}card-img-overlay-padding);
//   @include border-radius(var(--#{$prefix}card-inner-border-radius));
// }

// .card-img,
// .card-img-top,
// .card-img-bottom {
//   width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
// }

// .card-img,
// .card-img-top {
//   @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
// }

// .card-img,
// .card-img-bottom {
//   @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
// }

// //
// // Card groups
// //

// .card-group {
//   // The child selector allows nested `.card` within `.card-group`
//   // to display properly.
//   > .card {
//     margin-bottom: var(--#{$prefix}card-group-margin);
//   }

//   @include media-breakpoint-up(sm) {
//     display: flex;
//     flex-flow: row wrap;
//     // The child selector allows nested `.card` within `.card-group`
//     // to display properly.
//     > .card {
//       // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
//       flex: 1 0 0%;
//       margin-bottom: 0;

//       + .card {
//         @include ltr-rtl("margin-left", 0);
//         @include ltr-rtl("border-left", 0);
//       }

//       // Handle rounded corners
//       @if $enable-rounded {
//         &:not(:last-child) {
//           @include border-end-radius(0);

//           .card-img-top,
//           .card-header {
//             // stylelint-disable-next-line property-disallowed-list
//             border-top-right-radius: 0;
//           }
//           .card-img-bottom,
//           .card-footer {
//             // stylelint-disable-next-line property-disallowed-list
//             border-bottom-right-radius: 0;
//           }
//         }

//         &:not(:first-child) {
//           @include border-start-radius(0);

//           .card-img-top,
//           .card-header {
//             // stylelint-disable-next-line property-disallowed-list
//             border-top-left-radius: 0;
//           }
//           .card-img-bottom,
//           .card-footer {
//             // stylelint-disable-next-line property-disallowed-list
//             border-bottom-left-radius: 0;
//           }
//         }
//       }
//     }
//   }
// }
