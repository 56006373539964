.p-datatable .p-datatable-tbody > tr {
  &.table-warning {
    background-color: rgba($color: #f4f4f4, $alpha: 1);
    opacity: 0.8;
  }
}

.role-admin {
  color: var(--yellow-500);
  font-weight: 700;
}

.role-user {
  color: rgb(160, 172, 226);
}

.role-all {
  color: var(--blue-500);
}

.p-datatable-tbody {
  height: 100%;
}

.p-datatable-emptymessage {
  height: 100%;
  font-size: xx-large;
}

.p-datatable-emptymessage > td {
  margin-bottom: 50px;
}

.p-datatable-wrapper {
  background-color: #fff;
}

.p-datatable > .p-component-overlay {
  background-color: rgb(255 255 255 / 57%) !important;
}

.p-datatable-flex-scrollable {
  max-height: 100%;
  height: auto !important;
}

.p-datatable .p-column-header-content {
  justify-content: center;
}
