.dark-mode .p-dialog .p-dialog-header {
  background-color: $dark-main-bg;
  color: $dark-main-txt;
}

.dark-mode .p-dialog .p-dialog-content {
  background-color: $dark-main-bg;
  color: $dark-main-txt;
}

.dark-mode .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: rgb(35, 38, 39);
}

.dark-mode .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: rgb(40, 43, 45) 0px 0px 0px 0.2rem;
}
