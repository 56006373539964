.main {
  background-image: url("src/assets/fond.jpg");
  background-size: cover;
  background-size: 2050px;
  height: 100%;
}

.context {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 3px;
}

.logo {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  margin-bottom: 1rem;
}
