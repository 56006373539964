.listItemContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.menu1 {
  display: flex;
  padding-left: 5px;
}

.menu2 {
  display: flex;
  padding-left: 15px;
}

.menu3 {
  display: flex;
  padding-left: 35px;
}

.menu4 {
  display: flex;
  padding-left: 55px;
}

.menu1open {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu2open {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu3open {
  background-color: rgba(0, 0, 0, 0.2);
}
