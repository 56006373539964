.dark-mode .sidebar {
  // scss-docs-start sidebar-css-vars
  --#{$prefix}sidebar-bg: #{$dark-sidebar-bg};

  --#{$prefix}sidebar-color: #{$dark-sidebar-color};
  --#{$prefix}sidebar-border-color: #{$dark-sidebar-border-color};
  --#{$prefix}sidebar-brand-color: #{$dark-sidebar-brand-color};
  --#{$prefix}sidebar-brand-height: #{$dark-sidebar-brand-height};
  --#{$prefix}sidebar-brand-bg: #{$dark-sidebar-brand-bg};
  --#{$prefix}sidebar-header-height: #{$dark-sidebar-header-height};
  --#{$prefix}sidebar-header-bg: #{$dark-sidebar-header-bg};
  --#{$prefix}sidebar-header-padding-x: #{$dark-sidebar-header-padding-x};
  --#{$prefix}sidebar-header-padding-y: #{$dark-sidebar-header-padding-y};
  --#{$prefix}sidebar-footer-bg: #{$dark-sidebar-footer-bg};
  --#{$prefix}sidebar-footer-height: #{$dark-sidebar-footer-height};
  --#{$prefix}sidebar-footer-padding-x: #{$dark-sidebar-footer-padding-x};
  --#{$prefix}sidebar-footer-padding-y: #{$dark-sidebar-footer-padding-y};
  --#{$prefix}sidebar-toggler-bg: #{$dark-sidebar-toggler-bg};
  --#{$prefix}sidebar-toggler-height: #{$dark-sidebar-toggler-height};
  --#{$prefix}sidebar-toggler-indicator: #{escape-svg(
      $dark-sidebar-toggler-indicator-icon
    )};
  --#{$prefix}sidebar-toggler-indicator-width: #{$dark-sidebar-toggler-indicator-width};
  --#{$prefix}sidebar-toggler-indicator-height: #{$dark-sidebar-toggler-indicator-height};
  --#{$prefix}sidebar-toggler-hover-bg: #{$dark-sidebar-toggler-hover-bg};
  --#{$prefix}sidebar-toggler-indicator-hover: #{escape-svg(
      $dark-sidebar-toggler-indicator-hover-icon
    )};

  --#{$prefix}sidebar-narrow-width: #{$dark-sidebar-narrow-width};

  --#{$prefix}sidebar-nav-title-padding-x: #{$dark-sidebar-nav-title-padding-x};
  --#{$prefix}sidebar-nav-title-padding-y: #{$dark-sidebar-nav-title-padding-y};
  --#{$prefix}sidebar-nav-title-margin-top: #{$dark-sidebar-nav-title-margin-top};
  --#{$prefix}sidebar-nav-title-color: #{$dark-sidebar-nav-title-color};
  --#{$prefix}sidebar-nav-link-padding-x: #{$dark-sidebar-nav-link-padding-x};
  --#{$prefix}sidebar-nav-link-padding-y: #{$dark-sidebar-nav-link-padding-y};
  --#{$prefix}sidebar-nav-link-color: #{$dark-sidebar-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$dark-sidebar-nav-link-bg};
  --#{$prefix}sidebar-nav-link-active-color: #{$dark-sidebar-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$dark-sidebar-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$dark-sidebar-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$dark-sidebar-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$dark-sidebar-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$dark-sidebar-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$dark-sidebar-nav-link-hover-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$dark-sidebar-nav-link-hover-icon-color};
  --#{$prefix}sidebar-nav-icon-width: #{$dark-sidebar-nav-icon-width};
  --#{$prefix}sidebar-nav-icon-height: #{$dark-sidebar-nav-icon-height};
  --#{$prefix}sidebar-nav-icon-font-size: #{$dark-sidebar-nav-icon-font-size};
  --#{$prefix}sidebar-nav-link-icon-color: #{$dark-sidebar-nav-link-icon-color};
  --#{$prefix}sidebar-nav-group-bg: #{$dark-sidebar-nav-group-bg};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$dark-sidebar-nav-group-toggle-show-color};
  // scss-docs-end sidebar-css-vars
}

// .dark-mode .sidebar-brand {
//   background: red !important;
// }
