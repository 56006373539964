// .bg-light.dark {
//   background-color: #{$dark-sidebar-bg} !important;
// }

//couleur des p-button-success
.dark-mode button.p-button-success {
  color: rgb(232, 230, 227);
}

//couleur de fond des inputtext
.dark-mode input.p-inputtext {
  background: $dark-main-bg;
  border: 1px solid $dark-main-txt;
  color: $dark-main-txt;
}

.dark-mode .p-inputswitch-checked .p-inputswitch-slider {
  background: $dark-blue-800 !important;
}

.dark-mode .p-dropdown {
  background-color: #9da5b1;
}

.dark-mode .p-dropdown-panel {
  background: #9da5b1;
}

.dark-mode body {
  color: white;
}

.dark-mode .card {
  background-color: $dark-main-bg;
  border: 1px solid $dark-main-txt;
}

.dark-mode .card .p-checkbox .p-checkbox-box {
  background-color: $dark-main-bg;
  border: 1px solid $dark-main-txt;
}

.dark-mode .card .p-checkbox .p-checkbox-box.p-highlight {
  background-color: rgb(13, 15, 140);
  border-color: rgb(13, 15, 140);
}

.dark-mode
  .card
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background-color: rgb(13, 15, 160);
  border-color: rgb(45, 37, 140);
}

//Dropdowns :

.dark-mode .p-dropdown-panel {
  background-color: $dark-main-bg;
}

.dark-mode .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: $dark-main-txt;
}

.dark-mode
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: rgb(35, 38, 39);
  color: $dark-main-txt;
}

.dark-mode .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: rgb(75, 120, 207);
  background-color: rgb(35, 38, 39);
}

.dark-mode .p-dropdown:not(.p-disabled):hover {
  border-color: rgb(13, 15, 140) !important;
}

.dark-mode .p-dropdown-label {
  color: $dark-main-txt;
}

.dark-mode .dzu-dropzone {
  background: $dark-main-bg !important;
}
