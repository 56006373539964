.dark-mode .p-datatable-header {
  background: $dark-main-bg !important;
  border-width: 0px !important;
}

//header contenant la pagination
.dark-mode .p-datatable-header div:only-child {
  background: $dark-main-bg;
  border-color: black;
  //border-width: 0 0 0 0 !important;
}

//intérieur du champ input
.dark-mode .p-datatable-header input.p-inputtext {
  // background: $dark-main-bg !important;
  border: 1px solid rgb(60, 65, 68);
  // color: $dark-main-txt;
}

//titres de la datatable
.dark-mode .p-datatable .p-datatable-thead > tr > th {
  background: rgb(27, 30, 31) !important;
  color: $dark-main-txt;
  border: 1px solid black !important;
  border-width: 0 0 1px 0 !important;
}

.dark-mode
  .p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background-color: rgb(35, 38, 39) !important;
  color: rgb(195, 190, 182);
}

//focus sur le titre de la colonne
.dark-mode .p-datatable .p-sortable-column:focus {
  box-shadow: rgb(40, 43, 45) 0px 0px 0px 0.15rem inset;
}

//coloration du titre de la colonne sélectionnée
.dark-mode .p-datatable .p-sortable-column.p-highlight {
  color: rgb(75, 120, 207);
}
//coloration de l'icone de tri de la colonne sélectionnée
.dark-mode .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgb(75, 120, 207);
}

.dark-mode
  .p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
  .p-sortable-column-icon {
  color: rgb(195, 190, 182);
}

.dark-mode .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $dark-main-txt;
}

.dark-mode .p-datatable .p-datatable-footer,
.p-datatable .p-datatable-thead > tr > th {
  border-color: $dark-main-txt;
}

//texte du tableau
.dark-mode .p-datatable .p-datatable-tbody > tr {
  color: $dark-main-txt;
  border-color: rgb(56, 61, 63);
}

.dark-mode .p-datatable .p-datatable-tbody > tr > td {
  border-width: 0 0 1px;
  border: none;
}

//survol de la ligne
.dark-mode
  .p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: rgb(35, 38, 39) !important;
  color: $dark-main-txt;
}

//checkbox de la datatable
.dark-mode thead .p-checkbox-box {
  background: $dark-main-bg !important;
  border: 1px solid $dark-main-txt;
}

//r
.dark-mode .p-datatable .p-datatable-tbody > tr {
  background: $dark-main-bg;
  border-width: 0 0 1px 0 !important;
}
//checkbox du body
.dark-mode tbody .p-checkbox-box {
  background: $dark-main-bg;
  border: 1px solid $dark-main-txt;
}
.dark-mode tbody .p-checkbox-box:hover {
  border-color: $dark-blue-500 !important;
}

.dark-mode .p-datatable-footer {
  background: $dark-main-bg !important;
  border: 1px solid $dark-main-bg !important;
}

.dark-mode .p-datatable-footer * {
  background: $dark-main-bg !important;
  color: $dark-main-txt;
}
.dark-mode .p-datatable-footer .p-paginator {
  background: $dark-main-bg !important;
}

.dark-mode .p-dropdown {
  background: $dark-main-bg !important;
  border: 1px solid rgb(60, 65, 68) !important;
}

.dark-mode .p-datatable-header .p-dropdown-label {
  color: $dark-main-txt;
  // background: $dark-main-bg !important;
  // border: 1px solid rgb(60, 65, 68) !important;
}

.dark-mode .p-paginator-page.p-highlight {
  background: rgb(29, 31, 32) !important;
}

.dark-mode .p-paginator {
  background-color: $dark-main-bg;
}
