.listItemContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* .menu1 {
  display: flex;
} */

.menu1 > a {
  padding-left: 5px;
}

.menu2 > a {
  display: flex;
  padding-left: 15px;
}

/* .menu3 {
  display: flex;
  padding-left: 35px;
} */
.menu3 > a {
  padding-left: 35px;
}

/* .menu4 {
  display: flex;
  padding-left: 55px;
} */

.menu4 > a {
  padding-left: 55px;
}

.menu1test {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu2test {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu3test {
  background-color: rgba(0, 0, 0, 0.2);
}
