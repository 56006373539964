//
// General form controls (plus a few specific high-level interventions)
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: var(--#{$prefix}input-color, $input-color);
  background-color: var(--#{$prefix}input-bg, $input-bg);
  background-clip: padding-box;
  border: $input-border-width solid
    var(--#{$prefix}input-border-color, $input-border-color);
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &[type="file"] {
    overflow: hidden; // prevent pseudo element button overlap

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: var(--#{$prefix}input-focus-color, $input-focus-color);
    background-color: var(--#{$prefix}input-focus-bg, $input-focus-bg);
    border-color: var(
      --#{$prefix}input-focus-border-color,
      $input-focus-border-color
    );
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  // Add some height to date inputs on iOS
  // https://github.com/twbs/bootstrap/issues/23307
  // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
  &::-webkit-date-and-time-value {
    // Multiply line-height by 1em if it has no unit
    height: if(
      unit($input-line-height) == "",
      $input-line-height * 1em,
      $input-line-height
    );
  }

  // Placeholder
  &::placeholder {
    color: var(--#{$prefix}input-placeholder-color, $input-placeholder-color);
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  // File input buttons theming
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: var(--#{$prefix}form-file-button-color, $form-file-button-color);
    @include gradient-bg(
      var(--#{$prefix}form-file-button-bg, $form-file-button-bg)
    );
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    @include transition($btn-transition);
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(
      --#{$prefix}form-file-button-hover-bg,
      $form-file-button-hover-bg
    );
  }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  color: var(
    --#{$prefix}dark-input-plaintext-color,
    $dark-input-plaintext-color
  );
  background-color: transparent;
  border: solid transparent;
  border-width: $dark-input-border-width 0;
}
